import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "About",
      "Advantage",
      "Blog",
      "BlogGalleryArticle",
      "Career",
      "ComponentDataEntry",
      "ComponentDataHeading",
      "ComponentDataSet",
      "ComponentUiBlog",
      "ComponentUiButton",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "ContactAnswer",
      "Corporate",
      "CustomerReview",
      "Gallery",
      "Home",
      "HomeHeroBackground",
      "IconBenefit",
      "ImageBenefit",
      "Landlord",
      "Layout",
      "LocationApartment",
      "LocationArea",
      "LocationHouse",
      "NoomiStandard",
      "Page",
      "Partner",
      "Property",
      "ReactIconsIconlibrary",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ],
    "PageContentDynamicZone": [
      "ComponentUiText",
      "Error"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "About",
      "Advantage",
      "Blog",
      "BlogGalleryArticle",
      "Career",
      "ComponentDataEntry",
      "ComponentDataHeading",
      "ComponentDataSet",
      "ComponentUiBlog",
      "ComponentUiButton",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "ContactAnswer",
      "Corporate",
      "CustomerReview",
      "Gallery",
      "Home",
      "HomeHeroBackground",
      "IconBenefit",
      "ImageBenefit",
      "Landlord",
      "Layout",
      "LocationApartment",
      "LocationArea",
      "LocationHouse",
      "NoomiStandard",
      "Page",
      "Partner",
      "Property",
      "ReactIconsIconlibrary",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ],
    "PageContentDynamicZone": [
      "ComponentUiText",
      "Error"
    ]
  }
};
      export default result;
    
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const AdvantageFragmentDoc = gql`
    fragment Advantage on AdvantageEntity {
  id
  attributes {
    image {
      data {
        ...File
      }
    }
    title
    description
  }
}
    `;
export const BlogArticleFragmentDoc = gql`
    fragment BlogArticle on ComponentUiBlog {
  id
  button {
    id
    title
    url
    target
  }
  heading {
    id
    title
    subtitle
    article
    category
    date
    image {
      data {
        ...File
      }
    }
  }
}
    `;
export const BlogGalleryArticleFragmentDoc = gql`
    fragment BlogGalleryArticle on BlogGalleryArticleEntity {
  id
  attributes {
    itemId
    title
    subtitle
    article
    category
    date
    image {
      data {
        ...File
      }
    }
  }
}
    `;
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const GalleryFragmentDoc = gql`
    fragment Gallery on GalleryEntity {
  id
  attributes {
    image {
      data {
        ...File
      }
    }
  }
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const HomeHeroBackgroundFragmentDoc = gql`
    fragment HomeHeroBackground on HomeHeroBackgroundEntity {
  id
  attributes {
    image {
      data {
        ...File
      }
    }
  }
}
    `;
export const IconBenefitFragmentDoc = gql`
    fragment IconBenefit on IconBenefitEntity {
  id
  attributes {
    icon {
      data {
        ...File
      }
    }
    title
    description
  }
}
    `;
export const ImageBenefitFragmentDoc = gql`
    fragment ImageBenefit on ImageBenefitEntity {
  id
  attributes {
    image {
      data {
        ...File
      }
    }
    title
    description
  }
}
    `;
export const NoomiStandardFragmentDoc = gql`
    fragment NoomiStandard on NoomiStandardEntity {
  id
  attributes {
    standardId
    description
    icon {
      data {
        ...File
      }
    }
  }
}
    `;
export const LocationApartmentFullFragmentDoc = gql`
    fragment LocationApartmentFull on LocationApartmentEntity {
  id
  attributes {
    apartmentId
    image {
      data {
        ...File
      }
    }
    name
    bathroom
    bedroom
    features
    area
    apartmentParameters
    description
    gallery {
      data {
        ...File
      }
    }
    floorPlan {
      data {
        ...File
      }
    }
    noomi_standards {
      data {
        ...NoomiStandard
      }
    }
    parking
    petPolicy
    apartment
    building
  }
}
    `;
export const LocationHouseFragmentDoc = gql`
    fragment LocationHouse on LocationHouseEntity {
  id
  attributes {
    houseId
    image {
      data {
        ...File
      }
    }
    number
    name
    status
  }
}
    `;
export const LocationAreaFragmentDoc = gql`
    fragment LocationArea on LocationAreaEntity {
  id
  attributes {
    areaId
    title
    location_houses {
      data {
        ...LocationHouse
      }
    }
  }
}
    `;
export const LocationApartmentFragmentDoc = gql`
    fragment LocationApartment on LocationApartmentEntity {
  id
  attributes {
    apartmentId
    image {
      data {
        ...File
      }
    }
    name
    bathroom
    bedroom
    features
  }
}
    `;
export const CustomerReviewFragmentDoc = gql`
    fragment CustomerReview on CustomerReviewEntity {
  id
  attributes {
    image {
      data {
        ...File
      }
    }
    name
    place
    review
  }
}
    `;
export const LocationHouseFullFragmentDoc = gql`
    fragment LocationHouseFull on LocationHouseEntity {
  id
  attributes {
    houseId
    image {
      data {
        ...File
      }
    }
    number
    name
    status
    rate
    title
    description
    gallery {
      data {
        ...File
      }
    }
    location_apartments {
      data {
        ...LocationApartment
      }
    }
    reviews {
      data {
        ...CustomerReview
      }
    }
    amenitiesTitle
    amenitiesList
    utilitiesPaid
    appliancesList
    noomiPolicy
    neighborhoodDescription
    neighborhoodMap {
      data {
        ...File
      }
    }
    location
    publicTransit
    nearestPlace
    nearestPlaceDescription
    parking
  }
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const PageFragmentDoc = gql`
    fragment Page on PageEntity {
  id
  attributes {
    title
    pathname
    description
    keywords
    content {
      __typename
      ... on ComponentUiText {
        id
        visible
        children {
          ...Paragraph
        }
      }
    }
  }
}
    `;
export const PartnerFragmentDoc = gql`
    fragment Partner on PartnerEntity {
  id
  attributes {
    image {
      data {
        ...File
      }
    }
  }
}
    `;
export const PropertyFragmentDoc = gql`
    fragment Property on PropertyEntity {
  id
  attributes {
    image {
      data {
        ...File
      }
    }
    title
    type
    bathrooms
    price
  }
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  button {
    ...Link
  }
  heading {
    ...Card
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const FormHandlerDocument = gql`
    mutation formHandler($data: ContactAnswerInput!) {
  createContactAnswer(data: $data) {
    data {
      id
      attributes {
        firstName
      }
    }
  }
}
    `;
export type FormHandlerMutationFn = Apollo.MutationFunction<FormHandlerMutation, FormHandlerMutationVariables>;
export function useFormHandlerMutation(baseOptions?: Apollo.MutationHookOptions<FormHandlerMutation, FormHandlerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FormHandlerMutation, FormHandlerMutationVariables>(FormHandlerDocument, options);
      }
export type FormHandlerMutationHookResult = ReturnType<typeof useFormHandlerMutation>;
export type FormHandlerMutationResult = Apollo.MutationResult<FormHandlerMutation>;
export const AboutDocument = gql`
    query about {
  about {
    data {
      attributes {
        aboutHero {
          ...Section
        }
        ourMission {
          ...Section
        }
        aboutDuration {
          ...Section
        }
        aboutAdvantages {
          ...Section
        }
        aboutHelp {
          ...Section
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}`;
export function useAboutQuery(baseOptions?: Apollo.QueryHookOptions<AboutQuery, AboutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AboutQuery, AboutQueryVariables>(AboutDocument, options);
      }
export function useAboutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AboutQuery, AboutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AboutQuery, AboutQueryVariables>(AboutDocument, options);
        }
export type AboutQueryHookResult = ReturnType<typeof useAboutQuery>;
export type AboutLazyQueryHookResult = ReturnType<typeof useAboutLazyQuery>;
export type AboutQueryResult = Apollo.QueryResult<AboutQuery, AboutQueryVariables>;
export const AboutAdvantageDocument = gql`
    query aboutAdvantage {
  imageBenefits(filters: {slug: {eq: "aboutAdvantage"}}) {
    data {
      ...ImageBenefit
    }
  }
}
    ${ImageBenefitFragmentDoc}
${FileFragmentDoc}`;
export function useAboutAdvantageQuery(baseOptions?: Apollo.QueryHookOptions<AboutAdvantageQuery, AboutAdvantageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AboutAdvantageQuery, AboutAdvantageQueryVariables>(AboutAdvantageDocument, options);
      }
export function useAboutAdvantageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AboutAdvantageQuery, AboutAdvantageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AboutAdvantageQuery, AboutAdvantageQueryVariables>(AboutAdvantageDocument, options);
        }
export type AboutAdvantageQueryHookResult = ReturnType<typeof useAboutAdvantageQuery>;
export type AboutAdvantageLazyQueryHookResult = ReturnType<typeof useAboutAdvantageLazyQuery>;
export type AboutAdvantageQueryResult = Apollo.QueryResult<AboutAdvantageQuery, AboutAdvantageQueryVariables>;
export const AboutDurationDocument = gql`
    query aboutDuration {
  imageBenefits(filters: {slug: {eq: "aboutDuration"}}) {
    data {
      ...ImageBenefit
    }
  }
}
    ${ImageBenefitFragmentDoc}
${FileFragmentDoc}`;
export function useAboutDurationQuery(baseOptions?: Apollo.QueryHookOptions<AboutDurationQuery, AboutDurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AboutDurationQuery, AboutDurationQueryVariables>(AboutDurationDocument, options);
      }
export function useAboutDurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AboutDurationQuery, AboutDurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AboutDurationQuery, AboutDurationQueryVariables>(AboutDurationDocument, options);
        }
export type AboutDurationQueryHookResult = ReturnType<typeof useAboutDurationQuery>;
export type AboutDurationLazyQueryHookResult = ReturnType<typeof useAboutDurationLazyQuery>;
export type AboutDurationQueryResult = Apollo.QueryResult<AboutDurationQuery, AboutDurationQueryVariables>;
export const AboutHelpDocument = gql`
    query aboutHelp {
  iconBenefits(filters: {slug: {eq: "aboutHelp"}}) {
    data {
      ...IconBenefit
    }
  }
}
    ${IconBenefitFragmentDoc}
${FileFragmentDoc}`;
export function useAboutHelpQuery(baseOptions?: Apollo.QueryHookOptions<AboutHelpQuery, AboutHelpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AboutHelpQuery, AboutHelpQueryVariables>(AboutHelpDocument, options);
      }
export function useAboutHelpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AboutHelpQuery, AboutHelpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AboutHelpQuery, AboutHelpQueryVariables>(AboutHelpDocument, options);
        }
export type AboutHelpQueryHookResult = ReturnType<typeof useAboutHelpQuery>;
export type AboutHelpLazyQueryHookResult = ReturnType<typeof useAboutHelpLazyQuery>;
export type AboutHelpQueryResult = Apollo.QueryResult<AboutHelpQuery, AboutHelpQueryVariables>;
export const BlogDocument = gql`
    query blog {
  blog {
    data {
      attributes {
        blogHero {
          ...BlogArticle
        }
      }
    }
  }
}
    ${BlogArticleFragmentDoc}
${FileFragmentDoc}`;
export function useBlogQuery(baseOptions?: Apollo.QueryHookOptions<BlogQuery, BlogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlogQuery, BlogQueryVariables>(BlogDocument, options);
      }
export function useBlogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogQuery, BlogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlogQuery, BlogQueryVariables>(BlogDocument, options);
        }
export type BlogQueryHookResult = ReturnType<typeof useBlogQuery>;
export type BlogLazyQueryHookResult = ReturnType<typeof useBlogLazyQuery>;
export type BlogQueryResult = Apollo.QueryResult<BlogQuery, BlogQueryVariables>;
export const BlogGalleryDocument = gql`
    query blogGallery {
  blogGalleryArticles(pagination: {limit: 500}) {
    data {
      ...BlogGalleryArticle
    }
  }
}
    ${BlogGalleryArticleFragmentDoc}
${FileFragmentDoc}`;
export function useBlogGalleryQuery(baseOptions?: Apollo.QueryHookOptions<BlogGalleryQuery, BlogGalleryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlogGalleryQuery, BlogGalleryQueryVariables>(BlogGalleryDocument, options);
      }
export function useBlogGalleryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogGalleryQuery, BlogGalleryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlogGalleryQuery, BlogGalleryQueryVariables>(BlogGalleryDocument, options);
        }
export type BlogGalleryQueryHookResult = ReturnType<typeof useBlogGalleryQuery>;
export type BlogGalleryLazyQueryHookResult = ReturnType<typeof useBlogGalleryLazyQuery>;
export type BlogGalleryQueryResult = Apollo.QueryResult<BlogGalleryQuery, BlogGalleryQueryVariables>;
export const CareerDocument = gql`
    query career {
  career {
    data {
      attributes {
        careersHero {
          ...Section
        }
        careersMission {
          ...Section
        }
        careersProfessionalism {
          ...Section
        }
        careersValues {
          ...Section
        }
        careersCulture {
          ...Section
        }
        careersAdvantages {
          ...Section
        }
        careersContacts {
          ...Section
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}`;
export function useCareerQuery(baseOptions?: Apollo.QueryHookOptions<CareerQuery, CareerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CareerQuery, CareerQueryVariables>(CareerDocument, options);
      }
export function useCareerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CareerQuery, CareerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CareerQuery, CareerQueryVariables>(CareerDocument, options);
        }
export type CareerQueryHookResult = ReturnType<typeof useCareerQuery>;
export type CareerLazyQueryHookResult = ReturnType<typeof useCareerLazyQuery>;
export type CareerQueryResult = Apollo.QueryResult<CareerQuery, CareerQueryVariables>;
export const CareerAdvantagesDocument = gql`
    query careerAdvantages {
  iconBenefits(filters: {slug: {eq: "careerAdvantage"}}) {
    data {
      ...IconBenefit
    }
  }
}
    ${IconBenefitFragmentDoc}
${FileFragmentDoc}`;
export function useCareerAdvantagesQuery(baseOptions?: Apollo.QueryHookOptions<CareerAdvantagesQuery, CareerAdvantagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CareerAdvantagesQuery, CareerAdvantagesQueryVariables>(CareerAdvantagesDocument, options);
      }
export function useCareerAdvantagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CareerAdvantagesQuery, CareerAdvantagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CareerAdvantagesQuery, CareerAdvantagesQueryVariables>(CareerAdvantagesDocument, options);
        }
export type CareerAdvantagesQueryHookResult = ReturnType<typeof useCareerAdvantagesQuery>;
export type CareerAdvantagesLazyQueryHookResult = ReturnType<typeof useCareerAdvantagesLazyQuery>;
export type CareerAdvantagesQueryResult = Apollo.QueryResult<CareerAdvantagesQuery, CareerAdvantagesQueryVariables>;
export const CareerValuesDocument = gql`
    query careerValues {
  iconBenefits(filters: {slug: {eq: "careerValue"}}) {
    data {
      ...IconBenefit
    }
  }
}
    ${IconBenefitFragmentDoc}
${FileFragmentDoc}`;
export function useCareerValuesQuery(baseOptions?: Apollo.QueryHookOptions<CareerValuesQuery, CareerValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CareerValuesQuery, CareerValuesQueryVariables>(CareerValuesDocument, options);
      }
export function useCareerValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CareerValuesQuery, CareerValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CareerValuesQuery, CareerValuesQueryVariables>(CareerValuesDocument, options);
        }
export type CareerValuesQueryHookResult = ReturnType<typeof useCareerValuesQuery>;
export type CareerValuesLazyQueryHookResult = ReturnType<typeof useCareerValuesLazyQuery>;
export type CareerValuesQueryResult = Apollo.QueryResult<CareerValuesQuery, CareerValuesQueryVariables>;
export const CareersGalleryDocument = gql`
    query careersGallery {
  galleries(filters: {slug: {eq: "careers"}}) {
    data {
      ...Gallery
    }
  }
}
    ${GalleryFragmentDoc}
${FileFragmentDoc}`;
export function useCareersGalleryQuery(baseOptions?: Apollo.QueryHookOptions<CareersGalleryQuery, CareersGalleryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CareersGalleryQuery, CareersGalleryQueryVariables>(CareersGalleryDocument, options);
      }
export function useCareersGalleryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CareersGalleryQuery, CareersGalleryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CareersGalleryQuery, CareersGalleryQueryVariables>(CareersGalleryDocument, options);
        }
export type CareersGalleryQueryHookResult = ReturnType<typeof useCareersGalleryQuery>;
export type CareersGalleryLazyQueryHookResult = ReturnType<typeof useCareersGalleryLazyQuery>;
export type CareersGalleryQueryResult = Apollo.QueryResult<CareersGalleryQuery, CareersGalleryQueryVariables>;
export const CorporateDocument = gql`
    query corporate {
  corporate {
    data {
      attributes {
        corporateHero {
          ...Section
        }
        whyNoomi {
          ...Section
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}`;
export function useCorporateQuery(baseOptions?: Apollo.QueryHookOptions<CorporateQuery, CorporateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorporateQuery, CorporateQueryVariables>(CorporateDocument, options);
      }
export function useCorporateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorporateQuery, CorporateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorporateQuery, CorporateQueryVariables>(CorporateDocument, options);
        }
export type CorporateQueryHookResult = ReturnType<typeof useCorporateQuery>;
export type CorporateLazyQueryHookResult = ReturnType<typeof useCorporateLazyQuery>;
export type CorporateQueryResult = Apollo.QueryResult<CorporateQuery, CorporateQueryVariables>;
export const CorporateAdvantagesDocument = gql`
    query corporateAdvantages {
  advantages(filters: {slug: {eq: "corporate"}}) {
    data {
      ...Advantage
    }
  }
}
    ${AdvantageFragmentDoc}
${FileFragmentDoc}`;
export function useCorporateAdvantagesQuery(baseOptions?: Apollo.QueryHookOptions<CorporateAdvantagesQuery, CorporateAdvantagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorporateAdvantagesQuery, CorporateAdvantagesQueryVariables>(CorporateAdvantagesDocument, options);
      }
export function useCorporateAdvantagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorporateAdvantagesQuery, CorporateAdvantagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorporateAdvantagesQuery, CorporateAdvantagesQueryVariables>(CorporateAdvantagesDocument, options);
        }
export type CorporateAdvantagesQueryHookResult = ReturnType<typeof useCorporateAdvantagesQuery>;
export type CorporateAdvantagesLazyQueryHookResult = ReturnType<typeof useCorporateAdvantagesLazyQuery>;
export type CorporateAdvantagesQueryResult = Apollo.QueryResult<CorporateAdvantagesQuery, CorporateAdvantagesQueryVariables>;
export const CustomerReviewsDocument = gql`
    query customerReviews {
  customerReviews(filters: {slug: {eq: "customer"}}, pagination: {limit: 500}) {
    data {
      ...CustomerReview
    }
  }
}
    ${CustomerReviewFragmentDoc}
${FileFragmentDoc}`;
export function useCustomerReviewsQuery(baseOptions?: Apollo.QueryHookOptions<CustomerReviewsQuery, CustomerReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerReviewsQuery, CustomerReviewsQueryVariables>(CustomerReviewsDocument, options);
      }
export function useCustomerReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerReviewsQuery, CustomerReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerReviewsQuery, CustomerReviewsQueryVariables>(CustomerReviewsDocument, options);
        }
export type CustomerReviewsQueryHookResult = ReturnType<typeof useCustomerReviewsQuery>;
export type CustomerReviewsLazyQueryHookResult = ReturnType<typeof useCustomerReviewsLazyQuery>;
export type CustomerReviewsQueryResult = Apollo.QueryResult<CustomerReviewsQuery, CustomerReviewsQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      attributes {
        hero {
          ...Section
        }
        discover {
          ...Section
        }
        peaceful {
          ...Section
        }
        destination {
          ...Section
        }
        trustedBy {
          ...Section
        }
        latestProperties {
          ...Section
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const HomeGalleryDocument = gql`
    query homeGallery {
  galleries(filters: {slug: {eq: "home"}}) {
    data {
      ...Gallery
    }
  }
}
    ${GalleryFragmentDoc}
${FileFragmentDoc}`;
export function useHomeGalleryQuery(baseOptions?: Apollo.QueryHookOptions<HomeGalleryQuery, HomeGalleryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeGalleryQuery, HomeGalleryQueryVariables>(HomeGalleryDocument, options);
      }
export function useHomeGalleryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeGalleryQuery, HomeGalleryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeGalleryQuery, HomeGalleryQueryVariables>(HomeGalleryDocument, options);
        }
export type HomeGalleryQueryHookResult = ReturnType<typeof useHomeGalleryQuery>;
export type HomeGalleryLazyQueryHookResult = ReturnType<typeof useHomeGalleryLazyQuery>;
export type HomeGalleryQueryResult = Apollo.QueryResult<HomeGalleryQuery, HomeGalleryQueryVariables>;
export const HomeHeroBackgroundDocument = gql`
    query homeHeroBackground {
  homeHeroBackgrounds {
    data {
      ...HomeHeroBackground
    }
  }
}
    ${HomeHeroBackgroundFragmentDoc}
${FileFragmentDoc}`;
export function useHomeHeroBackgroundQuery(baseOptions?: Apollo.QueryHookOptions<HomeHeroBackgroundQuery, HomeHeroBackgroundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeHeroBackgroundQuery, HomeHeroBackgroundQueryVariables>(HomeHeroBackgroundDocument, options);
      }
export function useHomeHeroBackgroundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeHeroBackgroundQuery, HomeHeroBackgroundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeHeroBackgroundQuery, HomeHeroBackgroundQueryVariables>(HomeHeroBackgroundDocument, options);
        }
export type HomeHeroBackgroundQueryHookResult = ReturnType<typeof useHomeHeroBackgroundQuery>;
export type HomeHeroBackgroundLazyQueryHookResult = ReturnType<typeof useHomeHeroBackgroundLazyQuery>;
export type HomeHeroBackgroundQueryResult = Apollo.QueryResult<HomeHeroBackgroundQuery, HomeHeroBackgroundQueryVariables>;
export const CorporateBenefitsDocument = gql`
    query corporateBenefits {
  iconBenefits(filters: {slug: {eq: "corporate"}}) {
    data {
      ...IconBenefit
    }
  }
}
    ${IconBenefitFragmentDoc}
${FileFragmentDoc}`;
export function useCorporateBenefitsQuery(baseOptions?: Apollo.QueryHookOptions<CorporateBenefitsQuery, CorporateBenefitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorporateBenefitsQuery, CorporateBenefitsQueryVariables>(CorporateBenefitsDocument, options);
      }
export function useCorporateBenefitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorporateBenefitsQuery, CorporateBenefitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorporateBenefitsQuery, CorporateBenefitsQueryVariables>(CorporateBenefitsDocument, options);
        }
export type CorporateBenefitsQueryHookResult = ReturnType<typeof useCorporateBenefitsQuery>;
export type CorporateBenefitsLazyQueryHookResult = ReturnType<typeof useCorporateBenefitsLazyQuery>;
export type CorporateBenefitsQueryResult = Apollo.QueryResult<CorporateBenefitsQuery, CorporateBenefitsQueryVariables>;
export const LandlordDocument = gql`
    query landlord {
  landlord {
    data {
      attributes {
        landlordsHero {
          ...Section
        }
        landlordsBenefits {
          ...Section
        }
        landlordsDifferent {
          ...Section
        }
        landlordsMessage {
          ...Section
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}`;
export function useLandlordQuery(baseOptions?: Apollo.QueryHookOptions<LandlordQuery, LandlordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LandlordQuery, LandlordQueryVariables>(LandlordDocument, options);
      }
export function useLandlordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LandlordQuery, LandlordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LandlordQuery, LandlordQueryVariables>(LandlordDocument, options);
        }
export type LandlordQueryHookResult = ReturnType<typeof useLandlordQuery>;
export type LandlordLazyQueryHookResult = ReturnType<typeof useLandlordLazyQuery>;
export type LandlordQueryResult = Apollo.QueryResult<LandlordQuery, LandlordQueryVariables>;
export const LandlordBenefitsDocument = gql`
    query landlordBenefits {
  iconBenefits(filters: {slug: {eq: "landlordBenefit"}}) {
    data {
      ...IconBenefit
    }
  }
}
    ${IconBenefitFragmentDoc}
${FileFragmentDoc}`;
export function useLandlordBenefitsQuery(baseOptions?: Apollo.QueryHookOptions<LandlordBenefitsQuery, LandlordBenefitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LandlordBenefitsQuery, LandlordBenefitsQueryVariables>(LandlordBenefitsDocument, options);
      }
export function useLandlordBenefitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LandlordBenefitsQuery, LandlordBenefitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LandlordBenefitsQuery, LandlordBenefitsQueryVariables>(LandlordBenefitsDocument, options);
        }
export type LandlordBenefitsQueryHookResult = ReturnType<typeof useLandlordBenefitsQuery>;
export type LandlordBenefitsLazyQueryHookResult = ReturnType<typeof useLandlordBenefitsLazyQuery>;
export type LandlordBenefitsQueryResult = Apollo.QueryResult<LandlordBenefitsQuery, LandlordBenefitsQueryVariables>;
export const LandlordDifferentDocument = gql`
    query landlordDifferent {
  iconBenefits(filters: {slug: {eq: "landlordDifferent"}}) {
    data {
      ...IconBenefit
    }
  }
}
    ${IconBenefitFragmentDoc}
${FileFragmentDoc}`;
export function useLandlordDifferentQuery(baseOptions?: Apollo.QueryHookOptions<LandlordDifferentQuery, LandlordDifferentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LandlordDifferentQuery, LandlordDifferentQueryVariables>(LandlordDifferentDocument, options);
      }
export function useLandlordDifferentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LandlordDifferentQuery, LandlordDifferentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LandlordDifferentQuery, LandlordDifferentQueryVariables>(LandlordDifferentDocument, options);
        }
export type LandlordDifferentQueryHookResult = ReturnType<typeof useLandlordDifferentQuery>;
export type LandlordDifferentLazyQueryHookResult = ReturnType<typeof useLandlordDifferentLazyQuery>;
export type LandlordDifferentQueryResult = Apollo.QueryResult<LandlordDifferentQuery, LandlordDifferentQueryVariables>;
export const LayoutDocument = gql`
    query layout {
  layout {
    data {
      attributes {
        headerMenu {
          ...Link
        }
      }
    }
  }
}
    ${LinkFragmentDoc}`;
export function useLayoutQuery(baseOptions?: Apollo.QueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
      }
export function useLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
        }
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>;
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>;
export type LayoutQueryResult = Apollo.QueryResult<LayoutQuery, LayoutQueryVariables>;
export const LocationApartmentFullDocument = gql`
    query locationApartmentFull {
  locationApartments(pagination: {limit: 500}) {
    data {
      ...LocationApartmentFull
    }
  }
}
    ${LocationApartmentFullFragmentDoc}
${FileFragmentDoc}
${NoomiStandardFragmentDoc}`;
export function useLocationApartmentFullQuery(baseOptions?: Apollo.QueryHookOptions<LocationApartmentFullQuery, LocationApartmentFullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationApartmentFullQuery, LocationApartmentFullQueryVariables>(LocationApartmentFullDocument, options);
      }
export function useLocationApartmentFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationApartmentFullQuery, LocationApartmentFullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationApartmentFullQuery, LocationApartmentFullQueryVariables>(LocationApartmentFullDocument, options);
        }
export type LocationApartmentFullQueryHookResult = ReturnType<typeof useLocationApartmentFullQuery>;
export type LocationApartmentFullLazyQueryHookResult = ReturnType<typeof useLocationApartmentFullLazyQuery>;
export type LocationApartmentFullQueryResult = Apollo.QueryResult<LocationApartmentFullQuery, LocationApartmentFullQueryVariables>;
export const LocationAreasDocument = gql`
    query locationAreas {
  locationAreas(pagination: {limit: 500}) {
    data {
      ...LocationArea
    }
  }
}
    ${LocationAreaFragmentDoc}
${LocationHouseFragmentDoc}
${FileFragmentDoc}`;
export function useLocationAreasQuery(baseOptions?: Apollo.QueryHookOptions<LocationAreasQuery, LocationAreasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationAreasQuery, LocationAreasQueryVariables>(LocationAreasDocument, options);
      }
export function useLocationAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationAreasQuery, LocationAreasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationAreasQuery, LocationAreasQueryVariables>(LocationAreasDocument, options);
        }
export type LocationAreasQueryHookResult = ReturnType<typeof useLocationAreasQuery>;
export type LocationAreasLazyQueryHookResult = ReturnType<typeof useLocationAreasLazyQuery>;
export type LocationAreasQueryResult = Apollo.QueryResult<LocationAreasQuery, LocationAreasQueryVariables>;
export const LocationHouseDocument = gql`
    query locationHouse {
  locationHouses(pagination: {limit: 500}) {
    data {
      ...LocationHouse
    }
  }
}
    ${LocationHouseFragmentDoc}
${FileFragmentDoc}`;
export function useLocationHouseQuery(baseOptions?: Apollo.QueryHookOptions<LocationHouseQuery, LocationHouseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationHouseQuery, LocationHouseQueryVariables>(LocationHouseDocument, options);
      }
export function useLocationHouseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationHouseQuery, LocationHouseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationHouseQuery, LocationHouseQueryVariables>(LocationHouseDocument, options);
        }
export type LocationHouseQueryHookResult = ReturnType<typeof useLocationHouseQuery>;
export type LocationHouseLazyQueryHookResult = ReturnType<typeof useLocationHouseLazyQuery>;
export type LocationHouseQueryResult = Apollo.QueryResult<LocationHouseQuery, LocationHouseQueryVariables>;
export const LocationHouseFullDocument = gql`
    query locationHouseFull {
  locationHouses(pagination: {limit: 500}) {
    data {
      ...LocationHouseFull
    }
  }
}
    ${LocationHouseFullFragmentDoc}
${FileFragmentDoc}
${LocationApartmentFragmentDoc}
${CustomerReviewFragmentDoc}`;
export function useLocationHouseFullQuery(baseOptions?: Apollo.QueryHookOptions<LocationHouseFullQuery, LocationHouseFullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationHouseFullQuery, LocationHouseFullQueryVariables>(LocationHouseFullDocument, options);
      }
export function useLocationHouseFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationHouseFullQuery, LocationHouseFullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationHouseFullQuery, LocationHouseFullQueryVariables>(LocationHouseFullDocument, options);
        }
export type LocationHouseFullQueryHookResult = ReturnType<typeof useLocationHouseFullQuery>;
export type LocationHouseFullLazyQueryHookResult = ReturnType<typeof useLocationHouseFullLazyQuery>;
export type LocationHouseFullQueryResult = Apollo.QueryResult<LocationHouseFullQuery, LocationHouseFullQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const PageDocument = gql`
    query page($pathname: String) {
  pages(filters: {pathname: {eq: $pathname}}, pagination: {limit: 1}) {
    data {
      ...Page
    }
  }
}
    ${PageFragmentDoc}
${ParagraphFragmentDoc}`;
export function usePageQuery(baseOptions?: Apollo.QueryHookOptions<PageQuery, PageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageQuery, PageQueryVariables>(PageDocument, options);
      }
export function usePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageQuery, PageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, options);
        }
export type PageQueryHookResult = ReturnType<typeof usePageQuery>;
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>;
export type PageQueryResult = Apollo.QueryResult<PageQuery, PageQueryVariables>;
export const CorporatePartnersDocument = gql`
    query corporatePartners {
  partners {
    data {
      ...Partner
    }
  }
}
    ${PartnerFragmentDoc}
${FileFragmentDoc}`;
export function useCorporatePartnersQuery(baseOptions?: Apollo.QueryHookOptions<CorporatePartnersQuery, CorporatePartnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorporatePartnersQuery, CorporatePartnersQueryVariables>(CorporatePartnersDocument, options);
      }
export function useCorporatePartnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorporatePartnersQuery, CorporatePartnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorporatePartnersQuery, CorporatePartnersQueryVariables>(CorporatePartnersDocument, options);
        }
export type CorporatePartnersQueryHookResult = ReturnType<typeof useCorporatePartnersQuery>;
export type CorporatePartnersLazyQueryHookResult = ReturnType<typeof useCorporatePartnersLazyQuery>;
export type CorporatePartnersQueryResult = Apollo.QueryResult<CorporatePartnersQuery, CorporatePartnersQueryVariables>;
export const PropertiesDocument = gql`
    query properties {
  properties(pagination: {limit: 500}) {
    data {
      ...Property
    }
  }
}
    ${PropertyFragmentDoc}
${FileFragmentDoc}`;
export function usePropertiesQuery(baseOptions?: Apollo.QueryHookOptions<PropertiesQuery, PropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PropertiesQuery, PropertiesQueryVariables>(PropertiesDocument, options);
      }
export function usePropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PropertiesQuery, PropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PropertiesQuery, PropertiesQueryVariables>(PropertiesDocument, options);
        }
export type PropertiesQueryHookResult = ReturnType<typeof usePropertiesQuery>;
export type PropertiesLazyQueryHookResult = ReturnType<typeof usePropertiesLazyQuery>;
export type PropertiesQueryResult = Apollo.QueryResult<PropertiesQuery, PropertiesQueryVariables>;
export const TeamReviewsDocument = gql`
    query teamReviews {
  customerReviews(filters: {slug: {eq: "team"}}, pagination: {limit: 500}) {
    data {
      ...CustomerReview
    }
  }
}
    ${CustomerReviewFragmentDoc}
${FileFragmentDoc}`;
export function useTeamReviewsQuery(baseOptions?: Apollo.QueryHookOptions<TeamReviewsQuery, TeamReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamReviewsQuery, TeamReviewsQueryVariables>(TeamReviewsDocument, options);
      }
export function useTeamReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamReviewsQuery, TeamReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamReviewsQuery, TeamReviewsQueryVariables>(TeamReviewsDocument, options);
        }
export type TeamReviewsQueryHookResult = ReturnType<typeof useTeamReviewsQuery>;
export type TeamReviewsLazyQueryHookResult = ReturnType<typeof useTeamReviewsLazyQuery>;
export type TeamReviewsQueryResult = Apollo.QueryResult<TeamReviewsQuery, TeamReviewsQueryVariables>;