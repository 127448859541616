import "./LogoFooter.less"
import { FC } from "react"
import { ReactComponent as LogoImage } from "../LogoImage.svg"
import { ReactComponent as LogoText } from "../LogoText.svg"

const LogoFooter: FC = () => {
  return (
    <div className={"logo-footer-wrapper"} onClick={() => window.location.assign("/")}>
      <LogoImage className={"logo-footer-image"} />
      <LogoText className={"logo-footer-text"}/>
    </div>
  )
}

export { LogoFooter }
