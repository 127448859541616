import { Layout as BaseLayout } from "antd"
import React, { FC, Fragment, Suspense, useEffect, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { Footer, Header } from "."
import { HeaderMenu } from "../menu"
import { useBreakpoint } from "../../hooks/useBreakpoint"
import "./index.less"
import ContactUs from "../../components/contactUs"

const Layout: FC = () => {
  const [scroll, setScroll] = useState(false);
 
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1);
    });
  }, []);

  const location = useLocation();
  const { md, lg, xl, xxl } = useBreakpoint()
  
  return (
    <BaseLayout className={"layout"}>
      {location.pathname === "/locations"
        ? <React.Fragment>
          <BaseLayout.Header className={md || lg || xl || xxl
            ? "header-white"
            : scroll ? "header-white" : "header-black"
          }>
            <Header renderMenu={HeaderMenu} />
          </BaseLayout.Header>

          {md || lg || xl || xxl
          &&
            <Fragment></Fragment>
          }
        </React.Fragment>
        : <React.Fragment>
          <BaseLayout.Header className={scroll ? "header-white" : "header-black"}>
            <Header renderMenu={HeaderMenu} />
          </BaseLayout.Header>

          {md || lg || xl || xxl
          &&
            <Fragment></Fragment>
          }
        </React.Fragment>
      }

      <BaseLayout>
        <Suspense>
          <Outlet />
              <div
                className={"header-contact-us-wrapper"}
              >
                 <ContactUs/>
              </div>
        </Suspense>
      </BaseLayout>

      {location.pathname === "/locations"
        ? <Fragment></Fragment>
        : <BaseLayout.Footer
          className={"footer-wrapper"}
          style={{
            opacity: scroll ? "1" : "0",
          }}
        >
          <Footer />
        </BaseLayout.Footer>
      }
    </BaseLayout>
  )
}
export { Layout }
