import { Col, Row } from "antd"
import { FC, Fragment } from "react"
import { useBreakpoint } from "../../hooks/useBreakpoint"
import { Logo } from "../logo"
import { VirtualAssistance } from "../virtualAssistance/VirtualAssistance"
import { HeaderMenu } from "../menu"
import { LoginIcon } from "../loginIcon/LoginIcon"
import { Separator } from "../separator/Separator"

import Container from "../container/Container"

const Header: FC<{ renderMenu?: FC }> = ({ renderMenu: HorizontalMenu = HeaderMenu }) => {
  const { md, lg, xl, xxl } = useBreakpoint()

  return <Container>
  <Row align={"middle"} justify={"end"} >
    <Logo />
    
    <Col>
      <Row style={{ display: "flex", alignItems: "center" }}>
        {md || lg || xl || xxl
          ?<Fragment>
            <VirtualAssistance />
            <Separator /> 
          </Fragment>
          : <Fragment></Fragment>}
        
        <HorizontalMenu />
        
        {md || lg || xl || xxl
          ?<Fragment>
            <Separator /> 
            <LoginIcon />
          </Fragment>
          : <Fragment></Fragment>}
      </Row>
    </Col>
  </Row>
  </Container>
}

export { Header }
