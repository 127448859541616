import { FC } from "react"
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useParams } from "react-router-dom"
import { usePageQuery } from "../../graphql"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import PageContainer from "../pageContainer"
import NotFoundPage from "../not-found-page"
import { Section } from "../../components/section"

const Page: FC = () => {
    const { pathname } = useParams()
    const { data, loading, error } = usePageQuery({ variables: { pathname: `/${pathname}` || "" } })
    const page = data?.pages?.data?.[0]?.attributes

    const pageContent = page?.content?.[0] as ComponentUiText

    if (loading) return <PageContainer isLoading={loading} isError={!!error}/>
    if (!page) return <NotFoundPage />
    if (error) return <NotFoundPage />
    return (
        <HelmetProvider>
            <Helmet>
                <title>{page?.title} | noomi</title>
                <meta content={page?.description ?? page?.title ?? ""} name={'description'}></meta>
            </Helmet>

            <Section style={{padding:'200px 0'}}>
                {pageContent?.children?.map((item, index) =>
                    <ReactMarkdown key={index}>
                        {item?.value ?? ""}
                    </ReactMarkdown>
                )}
            </Section>
        </HelmetProvider>
    )
}

export { Page }