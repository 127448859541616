import { Button, Result } from "antd"
import { FC, PropsWithChildren, Fragment } from "react"
import { Loader } from "../../components/layout/Loader"

interface Props extends PropsWithChildren {
  isLoading: boolean
  isError: boolean
  className?: string
}
const PageContainer: FC<Props> = ({ isLoading, isError, children, className }) => {

  if (isLoading) {
    return <Loader spinning={isLoading} />
  }

  if (isError) {
    return (
      <Result
        status={'500'}
        title={'500'}
        subTitle={'Sorry, something went wrong.'}
        style={{ marginTop: "100px"}}
        extra={
          <Button
            href={"/"}
            style={{height:"50px", display:"inline-flex", alignItems:"center", fontWeight:"600"}}
          >
            Back Home
          </Button>
        }
      />
    )
  }

  return (
    <Fragment>
      <Loader spinning={isLoading} />
      <div className={className}>
        {children}
      </div>
    </Fragment>
  )
}

export default PageContainer
