import './index.less'
import { FC, Fragment, useState } from "react";
import { Button, Modal} from "antd";
import {ReactComponent as ChatIcon} from "./icons/contact-us.svg"
import { ContactUsContent } from "./contactUsContent/ContactUsContent";
import Container from '../../components/container/Container';

const ContactUs: FC = () => {
  const [open, setOpen] = useState(false);
  const onOpenModal=()=>{
    setOpen(true)
  }
  const handleCloseModal = () => {
    setOpen(false);
  };

  return(
    <Fragment>
      <Button 
          onClick={onOpenModal} 
          className={'contact-us-button'} 
      >
        <ChatIcon/>
      </Button>
      <Modal
          centered
          width={600}
          open={open}
          className={"contact-us-modal"}
          onOk={handleCloseModal}
          onCancel={handleCloseModal}
          footer={[]}
      >
        <Container>
          <ContactUsContent handleCloseModal={handleCloseModal}/>
        </Container>
      </Modal>
    </Fragment>
  )
}

export default ContactUs