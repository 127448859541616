import { Col, Row, Typography, Button } from "antd"
import { FC } from "react"
import { useBreakpoint } from "../../hooks/useBreakpoint"
import { LogoFooter } from "../logo/LogoFooter/LogoFooter"
import Container from "../container/Container"

const Footer: FC = () => {
  const { md, lg, xl, xxl } = useBreakpoint()

  return (
    <Container>
      {md || lg || xl || xxl
        ?<Col>
          <Row align={"top"} justify={"center"}>
            <Col span={12}>
              <LogoFooter/>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={8}>
                  <Row>
                    <Typography.Paragraph style={{ margin: "0 0 20px 0", fontSize:"16px", fontWeight:"600", color:"white"}}>
                      Guests
                    </Typography.Paragraph>
                  </Row>
                  <Row>
                    <Button type={"link"} href={"/locations"} className={"footer-link"}>
                      All locations
                    </Button>
                  </Row>
                  <Row>
                    <Button type={"link"} href={"/corporate"} className={"footer-link"}>
                      Corporate
                    </Button>
                  </Row>
                  <Row>
                    <Button type={"link"} href={"/landlords"} className={"footer-link"}>
                      For Landlords
                    </Button>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <Typography.Paragraph style={{ margin: "0 0 20px 0", fontSize:"16px", fontWeight:"600", color:"white"}}>
                      Company
                    </Typography.Paragraph>
                  </Row>
                  <Row>
                    <Button type={"link"} href={"/about"} className={"footer-link"}>
                      About us
                    </Button>
                  </Row>
                  <Row>
                    <Button type={"link"} href={"/careers"} className={"footer-link"}>
                      Careers
                    </Button>
                  </Row>
                  <Row>
                    <Button type={"link"} href={"/blog"} className={"footer-link"}>
                      Blog
                    </Button>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <Typography.Paragraph style={{ margin: "0 0 20px 0", fontSize:"16px", fontWeight:"600", color:"white"}}>
                      Contact us
                    </Typography.Paragraph>
                  </Row>
                  <Row>
                    <Button type={"link"} href={"mailto:contact@noomistays.com"} className={"footer-link"}>
                      Contact@noomistays.com
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row align={"top"} justify={"center"} style={{marginTop:"80px"}}>
            <Col span={12}>
              <Row>
                <Typography.Paragraph className={"footer-copyright"}>
                  Copyright © 2023 Noomi. All Rights Reserved.
                </Typography.Paragraph>
              </Row>
            </Col>
            <Col span={12}>
              <Row justify={"end"}>
                <Button type={"link"} className={"footer-link"} style={{marginRight:"40px"}}>
                  Privacy Policy
                </Button>
                <Button type={"link"} className={"footer-link"}>
                  Terms of Service
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>
        : <Col>
          <Row align={"top"} justify={"start"}>
            <Col span={24}>
              <LogoFooter/>
            </Col>
          </Row>

          <Row align={"top"} justify={"start"} style={{ margin: "0 0 24px 0"}}>
            <Col span={24}>
              <Row>
                <Typography.Paragraph style={{ margin: "0 0 20px 0", fontSize:"16px", fontWeight:"600", color:"white"}}>
                  Guests
                </Typography.Paragraph>
              </Row>
              <Row>
                <Button type={"link"} href={"/locations"} className={"footer-link"}>
                  All locations
                </Button>
              </Row>
              <Row>
                <Button type={"link"} href={"/corporate"} className={"footer-link"}>
                  Corporate
                </Button>
              </Row>
              <Row>
                <Button type={"link"} href={"/landlords"} className={"footer-link"}>
                  For Landlords
                </Button>
              </Row>
            </Col>
          </Row>

          <Row align={"top"} justify={"start"} style={{ margin: "0 0 24px 0"}}>
            <Col span={24}>
              <Row>
                <Typography.Paragraph style={{ margin: "0 0 20px 0", fontSize:"16px", fontWeight:"600", color:"white"}}>
                  Company
                </Typography.Paragraph>
              </Row>
              <Row>
                <Button type={"link"} href={"/about"} className={"footer-link"}>
                  About us
                </Button>
              </Row>
              <Row>
                <Button type={"link"} href={"/careers"} className={"footer-link"}>
                  Careers
                </Button>
              </Row>
              <Row>
                <Button type={"link"} href={"/blog"} className={"footer-link"}>
                  Blog
                </Button>
              </Row>
            </Col>
          </Row>

          <Row align={"top"} justify={"start"}>
            <Col span={24}>
              <Row>
                <Typography.Paragraph style={{ margin: "0 0 20px 0", fontSize:"16px", fontWeight:"600", color:"white"}}>
                  Contact us
                </Typography.Paragraph>
              </Row>
              <Row>
                <Button type={"link"} href={"mailto:contact@noomistays.com"} className={"footer-link"}>
                  Contact@noomistays.com
                </Button>
              </Row>
            </Col>
          </Row>

          <Row align={"top"} justify={"start"}>
            <Col span={24}>
              <Row>
                <Button type={"link"} className={"footer-link"} style={{ margin: "0 0 6px 0"}}>
                  Privacy Policy
                </Button>
              </Row>
              <Row>
                <Button type={"link"} className={"footer-link"}>
                  Terms of Service
                </Button>
              </Row>
            </Col>
          </Row>

          <Row align={"top"} justify={"start"} style={{marginTop:"50px"}}>
            <Col span={24}>
              <Row>
                <Typography.Paragraph className={"footer-copyright"}>
                  Copyright © 2023 Noomi. All Rights Reserved.
                </Typography.Paragraph>
              </Row>
            </Col>
          </Row>
        </Col>
      }
    </Container>
  )
}

export { Footer }
