import "./VirtualAssistance.less"
import { FC, useEffect, useState, Fragment } from "react"
import { useLocation } from "react-router-dom"
import { ReactComponent as IconAssistance } from "./icon/IconAssistance.svg"
import { Typography } from "antd"

const VirtualAssistance: FC = () => {
  const [scroll, setScroll] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1);
    });
  }, []);
  
  return (
    <Fragment>
      {location.pathname === "/locations"
        ? <div className={"assistance-wrapper"}>
            <IconAssistance className={"assistance-image assistance-image-black"} />
            <Typography.Paragraph  className={"assistance-text assistance-text-black"}>Virtual assistance</Typography.Paragraph>
          </div>
        : <div className={"assistance-wrapper"}>
          <IconAssistance className={`assistance-image ${scroll ? "assistance-image-black" : "assistance-image-white"}`} />
          <Typography.Paragraph  className={`assistance-text ${scroll ? "assistance-text-black" : "assistance-text-white"}`}>Virtual assistance</Typography.Paragraph>
        </div>
      }
    </Fragment>
  )
}

export { VirtualAssistance }
