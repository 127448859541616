import React, { FC, PropsWithChildren } from "react"
import { useBreakpoint } from "../../hooks/useBreakpoint"
import { useToken } from "../../hooks/useToken"
import './index.less'

interface Props extends PropsWithChildren {
  hero?: boolean
  style?: React.CSSProperties
  className?: string
}

export const Section: FC<Props> = ({ children, hero, style, className }) => {
  const {
    token: {
      controlHeight,
      paddingContentVertical,
      paddingContentVerticalLG,
    },
  } = useToken()

  const { md, lg, xl, xxl } = useBreakpoint()

  const horizontalPadding = md || lg || xl || xxl ? paddingContentVertical : paddingContentVerticalLG

  const heroProperties = {
    minHeight: "100vh",
    paddingTop: md || lg || xl || xxl ? controlHeight * 2 + horizontalPadding + 106 : 130,
  }

  return (
    <section
      style={{
        transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",
        overflow: "hidden",
        ...(hero && heroProperties),
        ...style,
      }}
      className={`section-container ${className}`}
    >
      {children}
    </section>
  )
}
