import './DesktopMenu.less'
import { FC, useState, useEffect } from "react"
import { Dropdown, MenuProps } from "antd"
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { ReactComponent as BurgerMenu } from "./icons/burger-menu.svg"

const items: MenuProps['items'] = [
  {
    key: '1',
    label: (
      <div className={"menu-wrapper"}>
        <ul className={"menu-list"}>
          <li className={"menu-list-item"}>
            <p className={"menu-list-title"}>Travel</p>
          </li>
          <li className={"menu-list-item"}>
            <a href={"/locations"} className={"menu-list-link"}>All locations</a>
          </li>
          <li className={"menu-list-item"}>
            <a href={"/corporate"} className={"menu-list-link"}>Corporate</a>
          </li>
          <li className={"menu-list-item"}>
            <a href={"/landlords"} className={"menu-list-link"}>For Landlords</a>
          </li>
        </ul>
        <ul className={"menu-list"}>
          <li className={"menu-list-item"}>
            <p className={"menu-list-title"}>Company</p>
          </li>
          <li className={"menu-list-item"}>
            <a href={"/about"} className={"menu-list-link"}>About us</a>
          </li>
          <li className={"menu-list-item"}>
            <a href={"/careers"} className={"menu-list-link"}>Careers</a>
          </li>
          <li className={"menu-list-item"}>
            <a href={"/blog"} className={"menu-list-link"}>Blog</a>
          </li>
        </ul>
      </div>
    ),
  },
];

const HeaderMenu: FC = () => {
  const [scroll, setScroll] = useState(false);
  const { md, lg, xl, xxl } = useBreakpoint()

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1);
    });
  }, []);

  return (
    <Dropdown menu={{ items }}>
      <a onClick={(e) => e.preventDefault()} style={{
        marginTop: md || lg || xl || xxl ? "10px" : "6px"
      }}>
        <BurgerMenu style={{
          fill: scroll
            ? "#1E1E1E"
            : "white"
        }} className={"menu-icon"} />
      </a>
  </Dropdown>
  )
}

export { HeaderMenu }