import "./LoginIcon.less"
import { FC, useEffect, useState, Fragment } from "react"
import { useLocation } from "react-router-dom"
import { ReactComponent as IconLogin } from "./icon/iconLogin.svg"

const LoginIcon: FC = () => {
    const [scroll, setScroll] = useState(false);
    const location = useLocation();

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 1);
        });
    }, []);
    
    return (
        <Fragment>
            {location.pathname === "/locations"
                ? <IconLogin className={"login-icon login-icon-black"} />
                : <IconLogin className={`login-icon ${scroll ? "login-icon-black" : "login-icon-white"}`} />
            }
        </Fragment>
    )
}

export { LoginIcon }
