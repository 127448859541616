import { FC, lazy } from "react"
import { RouteObject } from "react-router-dom"
import DefaultLayout, { Loader } from "../components/layout"
import { Page } from "./post/Page"

const Home = lazy<FC>(() => import("./home"))
const Locations = lazy<FC>(() => import("./locations"))
const House = lazy<FC>(() => import("./house"))
const Apartment = lazy<FC>(() => import("./apartment"))
const Landlords = lazy<FC>(() => import("./landlords"))
const Business = lazy<FC>(() => import("./business"))
const About = lazy<FC>(() => import("./about"))
const Careers = lazy<FC>(() => import("./careers"))
const Blog = lazy<FC>(() => import("./blog"))
const BlogArticle = lazy<FC>(() => import("./blogArticle"))
const NotFoundPage = lazy<FC>(() => import("./not-found-page"))

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Home,
        path: "",
        index: true,
      },
      {
        Component: Locations,
        path: "locations",
      },
      {
        Component: House,
        path: "locations/:houseId",
      },
      {
        Component: Apartment,
        path: "locations/:houseId/:apartmentId",
      },
      {
        Component: Landlords,
        path: "landlords",
      },
      {
        Component: Business,
        path: "corporate",
      },
      {
        Component: About,
        path: "about",
      },
      {
        Component: Careers,
        path: "careers",
      },
      {
        Component: Blog,
        path: "blog",
      },
      {
        Component: BlogArticle,
        path: "blog/:blogId",
        index: false
      },
      {
        Component: Page,
        path: ":pathname",
      },
      {
        Component: NotFoundPage,
        path: "*",
      },
    ],
  },
]

export default routes
