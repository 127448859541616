import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorActiveBarHeight: 0,
      colorPrimaryBg: "unset",
    },
    Layout: {
      colorBgLayout: "unset",
      colorBgHeader: "unset",
      colorBgBody: "unset",
      controlHeight: 31,
    },
  },
  token: {
    fontSize: 18,
    fontFamily: "Raleway, sans-serif",
    colorText: "#111111",
    colorTextSecondary: "#061432",
    colorPrimary: "#7A5540",
    colorPrimaryBg: "#F9F3ED",
    paddingContentVertical: 20,
    paddingContentVerticalLG: 16,
    paddingContentHorizontal: 48,
    paddingContentHorizontalLG: 10,
    controlHeight: 32,
    fontSizeHeading1: 72,
    lineHeightHeading1: 1.08,
    fontSizeHeading2: 48,
    lineHeightHeading2: 1.18,
    fontSizeHeading3: 24,
    fontSizeHeading4: 16,
    lineHeightHeading4: 1.5,
  },
}
export default theme