import  { FC, useState } from 'react'
import { Col, Input, Row, Select, Space, Typography, Form, Button } from 'antd'
import { OPTIONS } from './constants'
import { useFormHandlerMutation } from "../../../graphql"
import "./ContactUsContent.less"

type PointerEvents = "auto" |
                     "none" |
                     "visiblePainted" |
                     "visibleFill" | 
                     "visibleStroke" | 
                     "visible" | 
                     "painted" | 
                     "fill" | 
                     "stroke" | 
                     "all" | 
                     "inherit" | 
                     "initial" | 
                     "unset";

interface Props{
  handleCloseModal:()=>void
}

export const ContactUsContent:FC<Props> = ({handleCloseModal}) => {
  const [countryCode, setCountryCode]=useState(OPTIONS[0].value)
  
  const [sendContactUs] = useFormHandlerMutation()
  const [formValid, setFormValid] = useState(false)
  const [form] = Form.useForm()

  const onFinish = async (values: any) => {
    try {
      
      const response = await sendContactUs({
        variables: {
          data: {
            ...values,
            countryCode
          },
        },
      })
      if (response && response.data) {
          form.resetFields()
            }
    } catch (error) {
     console.log("Error submitting the form. Please try again."); 
    }
    handleCloseModal()
  }

  const handleSelectCountry=(value:string)=>{
      setCountryCode(value)
  }

  const [isThirdInputHovered, setIsThirdInputHovered] = useState(false);
  const [isThirdInputFocused, setIsThirdInputFocused] = useState(false);

  const handleThirdInputMouseEnter = () => {
    setIsThirdInputHovered(true);
  };

  const handleThirdInputMouseLeave = () => {
    setIsThirdInputHovered(false);
  };

  const handleThirdInputFocus = () => {
    setIsThirdInputFocused(true);
  };

  const handleThirdInputBlur = () => {
    setIsThirdInputFocused(false);
  };

  const styles={
    contentTitle: {
      margin: "0 0 10px 0",
      color: "#111111",
      fontSize: "14px",
      fontWeight:"600"
    },
    contentNumberInput:{
      borderLeft: "none",
 
    },
    firstInput: {
      borderRightColor: "transparent",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      pointerEvents: "none" as PointerEvents, 
      width: "70px",
      height:"32px",
      ...(isThirdInputHovered || isThirdInputFocused
        ? {
            borderLeftColor: "#7A5540",
            borderTopColor: "#7A5540",
            borderBottomColor: "#7A5540"
          }
        : {})
    }
  }
 
  return (
    <Row align={"middle"} justify={"start"}>
          <Col span={24} >
          <Form
            form={form}
            onFinish={onFinish}
            onValuesChange={(changedValues, values) => {
              const condition = values.firstName !== undefined
                  && values.lastName !== undefined
                  && values.email !== undefined 
                  && values.company !== undefined
                  && values.message !== undefined
              setFormValid(condition)    
            }}
          >
              <Row align={"bottom"} justify={"space-between"}>
                  <Col xs={{span:24}} sm={{span:11}}>
                      <Typography.Paragraph style={styles.contentTitle}>First name</Typography.Paragraph>
                      <Form.Item
                          name={"firstName"}
                          rules={[
                            {
                              required: true,
                              message: "Input your first name",
                              transform: value => value.trim(),
                            },
                          ]}
                      >
                        <Input />
                      </Form.Item>
                  </Col>
                  <Col xs={{span:24}} sm={{span:11}}>
                      <Typography.Paragraph style={styles.contentTitle}>Last name</Typography.Paragraph>
                      <Form.Item
                          name={"lastName"}
                          rules={[
                            {
                              required: true,
                              message: "Input your last name",
                              transform: value => value.trim(),
                            },
                          ]}
                      >
                        <Input/>
                      </Form.Item>
                  </Col>
                </Row>
                <Row align={"bottom"} justify={"space-between"} >
                    <Col xs={{span:24}} sm={{span:11}}>
                        <Typography.Paragraph style={styles.contentTitle}>Company name</Typography.Paragraph>
                        <Form.Item
                          name={"company"}
                          rules={[
                            {
                              required: true,
                              message: "Input your company",
                              transform: value => value.trim(),
                            },
                          ]}
                      >
                        <Input/>
                      </Form.Item>
                      
                    </Col>
                    <Col xs={{span:24}} sm={{span:11}}>
                        <Typography.Paragraph style={styles.contentTitle}>Email</Typography.Paragraph>
                        <Form.Item
                          name={"email"}
                          rules={[
                            {
                              required: true,
                              message: "Input your email",
                              transform: value => value.trim(),
                            },
                          ]}
                      >
                        <Input />
                      </Form.Item>
                        
                    </Col>
              </Row>
                    <Col>
                        <Typography.Paragraph style={styles.contentTitle}>Phone number</Typography.Paragraph>
                         <Space.Compact style={{width:'100%', marginRight:0}}>
                            <Select defaultValue={OPTIONS[0].label}
                                    options={OPTIONS} 
                                    onSelect={(value) => handleSelectCountry(value)}
                            />
                              <Input defaultValue={countryCode} value={countryCode}
                                style={styles.firstInput}
                              />
                          
                            <Form.Item
                              name={"phone"}
                              style={{width:"100%"}}
                            >
                              <Input 
                                style={styles.contentNumberInput}
                                onMouseEnter={handleThirdInputMouseEnter}
                                onMouseLeave={handleThirdInputMouseLeave}
                                onFocus={handleThirdInputFocus}
                                onBlur={handleThirdInputBlur}
                              />
                            </Form.Item>
                         </Space.Compact>
                    </Col>
                        <Typography.Paragraph style={styles.contentTitle} >Message</Typography.Paragraph>
                        <Form.Item
                            name={"message"}
                        >
                        <Input.TextArea rows={2} style={{width:'100%'}}/>
                        </Form.Item>
                        <Button 
                            key={"back"} 
                            className={"contact-us-button-close"}
                            onClick={form.submit}
                            disabled={!formValid}
                        >
                          Submit
                        </Button>
              </Form>
          </Col>
      </Row>
  )
}
