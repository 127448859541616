import "./index.less"
import { FC, useEffect, useState, Fragment } from "react"
import { useLocation, Link } from "react-router-dom"
import { useBreakpoint } from "../../hooks/useBreakpoint"
import { ReactComponent as LogoImage } from "./LogoImage.svg"
import { ReactComponent as LogoText } from "./LogoText.svg"

const Logo: FC = () => {
  const [scroll, setScroll] = useState(false);
  const location = useLocation();
  const { md, lg, xl, xxl } = useBreakpoint()

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1);
    });
  }, []);
  
  return (
    <Fragment>
      {location.pathname === "/locations"
        ? md || lg || xl || xxl
          ?<Link to={"/"} className={"logo-wrapper logo-wrapper-move"}>
              <LogoImage className={"logo-image logo-image-move"} />
              <LogoText  className={"logo-text logo-text-move"}/>
          </Link>
          :<Link to={"/"} className={`logo-wrapper ${scroll ? "logo-wrapper-move" : "logo-wrapper-static"}`}>
            <LogoImage className={`logo-image ${scroll ? "logo-image-move" : "logo-image-static"}`} />
            <LogoText  className={`logo-text ${scroll ? "logo-text-move" : "logo-text-static"}`}/>
          </Link>
        :<Link to={"/"} className={`logo-wrapper ${scroll ? "logo-wrapper-move" : "logo-wrapper-static"}`}>
          <LogoImage className={`logo-image ${scroll ? "logo-image-move" : "logo-image-static"}`} />
          <LogoText  className={`logo-text ${scroll ? "logo-text-move" : "logo-text-static"}`}/>
        </Link>
      }
    </Fragment>
  )
}

export { Logo }
