import { Button, Result } from "antd"
import { FC } from "react"
import { Helmet, HelmetProvider } from 'react-helmet-async'

const NotFoundPage: FC = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>Page not found | noomi</title>
                <meta content={'Page not found'} name={'description'}></meta>
            </Helmet>

            <Result
                status={'404'}
                title={'404'}
                subTitle={'Sorry, the page you visited does not exist.'}
                style={{ marginTop: "100px"}}
                extra={
                    <Button href={"/"} style={{height:"50px", display:"inline-flex", alignItems:"center", fontWeight:"600"}}>
                    Back Home
                    </Button>
                }
            />
        </HelmetProvider>
    )
}

export default NotFoundPage
