export const OPTIONS = [
  {
    value: '+1',
    label: 'US',
  },
  {
    value: '+30',
    label: 'GR',
  },
  {
    value: '+31',
    label: 'NL',
  },
  {
    value: '+32',
    label: 'BE',
  },
  {
    value: '+33',
    label: 'FR',
  },
  {
    value: '+34',
    label: 'ES',
  },
  {
    value: '+36',
    label: 'HU',
  },
  {
    value: '+39',
    label: 'IT',
  },
  {
    value: '+40',
    label: 'RO',
  },
  {
    value: '+41',
    label: 'CH',
  },
  {
    value: '+43',
    label: 'AT',
  },
  {
    value: '+44',
    label: 'GB',
  },
  {
    value: '+45',
    label: 'DK',
  },
  {
    value: '+46',
    label: 'SE',
  },
  {
    value: '+47',
    label: 'NO',
  },
  {
    value: '+48',
    label: 'PL',
  },
  {
    value: '+49',
    label: 'DE',
  },
];