import "./Separator.less"
import { FC, useEffect, useState, Fragment } from "react"
import { useLocation } from "react-router-dom"
import { ReactComponent as IconSeparator } from "./icon/iconSeparator.svg"

const Separator: FC = () => {
    const [scroll, setScroll] = useState(false);
    const location = useLocation();

    useEffect(() => {
        window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 1);
        });
    }, []);
    
    return (
        <Fragment>
            {location.pathname === "/locations"
                ? <IconSeparator className={"separator separator-black"} />
                : <IconSeparator className={`separator ${scroll ? "separator-black" : "separator-white"}`} />
            }
        </Fragment>
    )
}

export { Separator }